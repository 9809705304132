let consumptionCCEE = {
  consumptionCCEE: {
    title: 'CCEE',
    name: 'Name',
    open: 'Open',
    consumption: 'consumptionCCEE',
    active_demand: 'active demand',
    accomplished: 'Accomplished',
    simulated: 'Simulated',
    graphicType: 'Graphic Type',
    disclaimerSimulatedTitle: 'Simulation Methodology',
    disclaimerSimulated:
      'The simulation tool used to estimate the consumption values on the missing days is based on records carried out at the same times and days of the previous weeks of the month of analysis. Therefore, to calculate the estimates it is necessary that there are at least 7 days of history within the same month. The analysis distinguishes only weekdays and weekends, that is, any national or regional holidays, and its consumption is estimated based on the same days of the previous weeks.',
    disclaimerMedicoes: 'The values of maximum recorded demand, their registration times, and the division of consumption between peak and off-peak periods are calculated based on the information collected from CCEE. Therefore, the amounts billed by the distributor may occasionally differ from what is displayed on this page.'
  }
};

export default consumptionCCEE;
